<template>
  <div>
    <div class="mb-2">
      <b-form>
        <validation-observer
          ref="createCreditNoteForm"
        >
          <portal to="body-top">
            <h3
              id="create_page_title"
              class="text-center font-weight-bolder mb-1"
            >
              {{ $t('Request for Credit Note') }}
            </h3>
            <quote-status-bar
              :quote-status-bar="quoteStatusBar"
            />
          </portal>

          <supplier-information ref="supplierInformation" />
          <credit />
          <totals class="mt-1" />
          <form-bottom :form-refs="$refs" />
        </validation-observer>

      </b-form>
    </div>
  </div>
</template>

<script>
import QuoteStatusBar from '@/views/main/orders/components/use-as-global/QuoteStatusBar.vue'
// eslint-disable-next-line import/no-cycle
import { ValidationObserver } from 'vee-validate'
import SupplierInformation from '@/views/main/orders/view/credit-note/components/SupplierInformation.vue'
import Credit from '@/views/main/orders/view/credit-note/components/Credit.vue'
import FormBottom from '@/views/main/orders/view/credit-note/components/FormBottom.vue'
import Totals from '@/views/main/orders/view/credit-note/components/Totals.vue'
// eslint-disable-next-line import/no-cycle
import { BForm } from 'bootstrap-vue'
import config from '../config'

export default {
  name: 'CreateView',
  components: {
    BForm,
    Totals,
    FormBottom,
    Credit,
    SupplierInformation,
    ValidationObserver,
    QuoteStatusBar,
  },
  computed: {
    quote() {
      return this.$store.state[this.MODULE_NAME].quote
    },
  },
  created() {

  },
  setup() {
    const { quoteStatusBar, MODULE_NAME } = config()

    return {
      MODULE_NAME,
      quoteStatusBar,
    }
  },
}
</script>
